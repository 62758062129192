import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Layout from "../components/Layout";

export const WinePageTemplate = () => {
  const [wines, setWines] = useState([]);
  // get apiKey in a secure way
  const apiKey = process.env.GATSBY_AIRTABLE_API_KEY;
  // get data from airtable
  useEffect(() => {
    axios.get(
        `https://api.airtable.com/v0/appY83HHVPDO06kfk/tblZv3JHNPcEs2p3m?api_key=${apiKey}`
      )
      .then((res) => {
        const records = res.data.records;
        const sortedRecords = records.sort((a, b) => {return a.fields.number < b.fields.number}).reverse();
        setWines(sortedRecords);
      });
  }, []);

  return (
    <div>
      <div
        className="full-width-image-container margin-top-0"
        style={{
          backgroundImage: `url('/img/wine-index.jpg')`,
        }}
      >
        <h1
          className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen"
          style={{
            color: "white",
            lineHeight: "1",
            padding: "0.25em",
            fontFamily: "Lulo",
          }}
        >
          Wine Menu
        </h1>
        <h2
          className="has-text-weight-bold is-size-5-mobile is-size-4-tablet is-size-3-widescreen"
          style={{
            color: "white",
            lineHeight: "1",
            padding: "0.25em",
            fontFamily: "Lulo",
            marginTop: "0.5em",
            textAlign: "center",
          }}
        >
          Coming Soon
        </h2>
      </div>{" "}
      <section className="section">
        <div className="row" id="wine">
          <div className="column" id="container">
            <ul>
              {wines.map((wine) => {
                return (
                  <li style={styles.wine}>
                    {" "}
                    {wine.fields.year} {wine.fields.item} | {wine.fields.region}{" "}
                    {wine.fields.price}{" "}
                  </li>
                );
              })}
            </ul>{" "}
          </div>{" "}
        </div>{" "}
      </section>{" "}
    </div>
  );
};

const styles = {
  wine: {
    fontWeight: 200,
    fontFamily: "Lulo",
    textAlign: "center",
    fontSize: 25,
  },
};

// WinePageTemplate.propTypes = {
//   title: PropTypes.string.isRequired,
//   content: PropTypes.string,
//   contentComponent: PropTypes.func,
// }

const WinePage = () => {
  return (
    <Layout>
      <WinePageTemplate />
    </Layout>
  );
};

export default WinePage;